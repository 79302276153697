<template>
  <div>
    <div class="text-center categ-section">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-3">
            <img class="cat-img" :src="categoryImage" alt="" />
          </div>
          <div class="col-md-6">
            <h1 class="categoryHeadd f-bold text-capitalize">Sterling Human</h1>
            <p class="mb-0 text-dark">
              Get powered up for better productivity with Imperium!. Click
              <a href="https://www.imperiumng.com/testimonials">here</a> for
              testimonials from other #SterlingHumans

              <!-- <span class="text-capitalize">{{ categoryData }} </span> -->
            </p>
            <!-- <p class="mb-0 text-dark">Shop your choice of product!</p> -->
            <!-- <div class="category-select" id="CategoryView">
              <span>Category</span>
              <select
                @change="selectChange()"
                class="form-control"
                name=""
                id=""
              >
                <option value="low">Low Cost</option>
                <option value="high">High Cost</option>
              </select>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="prods bg-lightt">
      <div class="container">
        <loading v-if="loading" />
        <!-- <div v-else class="row"> -->
        <!-- <div class="col-md-3" > -->
        <div class="row justify-content-center">
          <div class="col-md-6 col-xl-3 col-lg-4">
            <div class="crd">
              <div>
                <div class="imgg w-100">
                  <img
                    class="w-100"
                    src="http://res.cloudinary.com/imperiumng/image/upload/v1672418851/production/p0fnwdk9ruq61whoc3om.png"
                    alt=""
                  />
                </div>
                <div class="ttle">
                  <h2>1.5KVA Solar Inverter System</h2>
                </div>
                <div class="load">
                  <h4>Recommended Load</h4>
                  <ul>
                    <li>Laptops - 1pc</li>
                    <li>43in LED TV - 1pc</li>
                    <li>TV Decoder - 1pc</li>
                    <li>Sound System - 1pc</li>
                    <li>Standing Fan - 1pc</li>
                    <li>LED Bulbs(5W) - 5pcs</li>
                  </ul>
                </div>
                <div class="comps">
                  <h4>Specifications</h4>

                  <ul>
                    <li>Inverter : 1.5KVA</li>
                    <li>40A MPPT Charge Controller</li>
                    <li>200AH X 2PCS</li>
                    <li>Lithium Battery</li>
                    <li>Solar PV: 400W X 2PCS</li>
                    <li>VAT: 0%</li>
                  </ul>
                </div>
                <div class="prcs">
                  <h4>Pricing</h4>
                  <p>
                    <span>₦1,113,210</span>
                  </p>
                </div>
              </div>
              <span
                class="fbt"
                @click="
                  kva2 = true;
                  popup = true;
                "
                >Buy Now</span
              >
            </div>
          </div>
          <div class="col-md-6 col-xl-3 col-lg-4">
            <div class="crd">
              <div>
                <div class="imgg w-100">
                  <img
                    class="w-100"
                    src="http://res.cloudinary.com/imperiumng/image/upload/v1672418851/production/p0fnwdk9ruq61whoc3om.png"
                    alt=""
                  />
                </div>
                <div class="ttle">
                  <h2>1KVA Solar Inverter System</h2>
                </div>
                <div class="load">
                  <h4>Recommended Load</h4>
                  <ul>
                    <li>Laptop - 1pcs</li>
                    <li>LED TV (32") - 1pcs</li>
                    <li>Sound System - 1pcs</li>
                    <li>Table Fan - 1pcs</li>
                    <li>LED Bulbs(5W) - 5pcs</li>
                  </ul>
                </div>
                <div class="comps">
                  <h4>Specifications</h4>

                  <ul>
                    <li>MPPT Controller : 40A</li>
                    <li>Inverter : 1KVA</li>
                    <li>Lead Batteery : 200A X 1PC</li>
                    <li>Solar PV: 400W X 1PC</li>
                    <li>VAT: 0%</li>
                  </ul>
                </div>
                <div class="prcs">
                  <h4>Pricing</h4>
                  <p>
                    <span>₦682,500</span>
                  </p>
                </div>
              </div>
              <span
                class="fbt"
                @click="
                  kva1 = true;
                  popup = true;
                "
                >Buy Now</span
              >
            </div>
          </div>
          <!-- <div class="col-md-6 col-xl-3 col-lg-4">
                <div class="crd">
                  <div>
                    <div class="imgg">
                      <img src="/assets/images/special-offer.jpg" alt="" />
                    </div>
                    <div class="ttle">
                      <h2>
                        Standard Package <br />
                        3.5 KVA
                      </h2>
                    </div>
                    <div class="load">
                      <h4>Recommended Load</h4>

                      <ul>
                        <li>Laptops - 4pcs</li>
                        <li>LED TV ( &lt; 65 Inch) - 1pcs</li>
                        <li>Ceiling Fans - 2pcs</li>
                        <li>Standing Fan - 4pcs</li>
                        <li>Fridge Or Freezer (Energy-Savers) - 1pcs</li>
                        <li>Bulb (Energy-Savers) - 10pcs</li>
                      </ul>
                    </div>
                    <div class="comps">
                      <h4>Specifications</h4>

                      <p>
                        <span> 3.5 KVA </span> (18mo Warranty) | 60A MPPT |
                        10kwh/48v Lithium Battery | 1.8Kwp
                      </p>
                    </div>
                    <div class="prcs">
                      <h4>Pricing</h4>
                      <p>
                        <span>₦2,773,680</span>
                      </p>
                    </div>
                  </div>
                  <a
                    href="https://shop.imperiumng.com/product/standard-package-3-5kva?category=package"
                    class="fbt"
                    >Buy Now</a
                  >
                </div>
              </div>
              <div class="col-md-6 col-xl-3 col-lg-4">
                <div class="crd">
                  <div>
                    <div class="imgg">
                      <img src="/assets/images/special-offer.jpg" alt="" />
                    </div>
                    <div class="ttle">
                      <h2>
                        Standard Plus Package <br />
                        5 KVA
                      </h2>
                    </div>
                    <div class="load">
                      <h4>Recommended Load</h4>

                      <ul>
                        <li>Laptops - 5pcs</li>
                        <li>LED TV ( &lt; 100 Inch) - 2pcs</li>
                        <li>Ceiling Fans - 3pcs</li>
                        <li>Standing Fan - 4pcs</li>
                        <li>Air-Conditioner (Energy-Savers 1hp) - 1pcs</li>
                        <li>Fridge Or Freezer (Energy Savers) - 1pcs</li>
                        <li>Bulb (Energy-Savers) - 12pcs</li>
                      </ul>
                    </div>
                    <div class="comps">
                      <h4>Specifications</h4>

                      <p>
                        <span> 5 KVA </span> | 80A | 10kwh/48v Lithium Battery |
                        1.8kwp
                      </p>
                    </div>
                    <div class="prcs">
                      <h4>Pricing</h4>
                      <p>
                        <span>₦3,023,527.50</span>
                      </p>
                    </div>
                  </div>
                  <a
                    href="https://shop.imperiumng.com/product/standard-plus-package-5kva?category=package"
                    class="fbt"
                    >Buy Now</a
                  >
                </div>
              </div> -->
        </div>
        <!-- </div> -->
        <!-- </div> -->
      </div>
    </div>
    <div class="popup" v-if="popup" @click.self="popup = false">
      <div class="inr">
        <h5>Kindly fill in your details</h5>
        <Loading v-if="loading" />
        <form v-else>
          <div class="form-group">
            <label for="name">Full Name</label>
            <input
              type="text"
              placeholder="John Doe"
              id="name"
              class="form-control"
              v-model="form.name"
            />
          </div>
          <div class="form-group">
            <label for="mail">Email Address</label>
            <input
              type="email"
              placeholder="john.doe@sterling.ng"
              id="mail"
              class="form-control"
              v-model="form.email"
            />
            <p
              class="red"
              v-if="
                form.email.length &&
                !form.email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)
              "
            >
              Invalid Email
            </p>
          </div>
          <div class="form-group">
            <label for="phone">Phone Number</label>
            <input
              type="number"
              placeholder="Phone Number"
              id="phone"
              class="form-control"
              v-model="form.phone_number"
            />
          </div>
          <div class="form-group">
            <label for="br">Branch</label>
            <input
              type="text"
              placeholder="Branch"
              id="br"
              class="form-control"
              v-model="form.branch"
            />
          </div>
          <div class="form-group">
            <label for="re">Region</label>
            <input
              type="text"
              placeholder="Region"
              id="re"
              class="form-control"
              v-model="form.region"
            />
          </div>
          <div class="form-group">
            <label>Choice <sub>Both can be selected</sub> </label>
            <div class="row">
              <div class="col-6">
                <div class="ch">
                  <input type="checkbox" id="1kva" v-model="kva1" />
                  <label for="1kva">1KVA</label>
                </div>
              </div>
              <div class="col-6">
                <div class="ch">
                  <input type="checkbox" id="1.5kva" v-model="kva2" />
                  <label for="1.5kva">1.5KVA</label>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <button :disabled="!valid" @click.prevent="Submit" class="fbt">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import HcProductCard from "../components/HcProductCard.vue";
import Loading from "../components/Loading.vue";
export default {
  components: { HcProductCard, Loading },
  data() {
    return {
      loading: false,
      categoryImage: "/assets/images/solar.png",
      popup: false,
      form: {
        name: "",
        phone_number: "",
        email: "",
        branch: "",
        region: "",
      },
      kva1: false,
      kva2: false,
      //prev
      // myd: [
      //   {
      //     name: "1.5KVA Solar Inverter System",
      //     display_image:
      //       "http://res.cloudinary.com/imperiumng/image/upload/v1672418851/production/p0fnwdk9ruq61whoc3om.png",
      //     price: 1113210,
      //     url: "/product/1-5kva-solar-inverter-system?category=package",
      //     description: "",
      //   },
      //   {
      //     name: "1KVA Solar Inverter System",
      //     display_image:
      //       "http://res.cloudinary.com/imperiumng/image/upload/v1672418851/production/p0fnwdk9ruq61whoc3om.png",
      //     price: 682500,
      //     url: "/product/1kva-solar-inverter-system?category=package",
      //     description: "",
      //   },
      //   // {
      //   //   name: "Standard Package (3.5Kva)",
      //   //   display_image:
      //   //     "http://res.cloudinary.com/imperiumng/image/upload/v1653297359/production/vwczwtik89tcttnumcyl.png",
      //   //   price: 2773680,
      //   //   url: "/product/standard-package-3-5kva",
      //   //   description: "",
      //   // },
      //   // {
      //   //   name: "Standard Plus Package (5Kva)",
      //   //   display_image:
      //   //     "http://res.cloudinary.com/imperiumng/image/upload/v1653297359/production/vwczwtik89tcttnumcyl.png",
      //   //   price: 3023527,
      //   //   url: "/product/standard-plus-package-5kva",
      //   //   description: "",
      //   // },
      //   // {
      //   //   name: "MAXXI 1200WH SP",
      //   //   display_image:
      //   //     "http://res.cloudinary.com/imperiumng/image/upload/v1624012406/production/q0njud4t3v98nzettjdo.png",
      //   //   price: 490000,
      //   //   url: "/product/maxxi-1200wh-sp-0001",
      //   //   description:
      //   //     "MAXXI 1200Wh is a HUGE CAPACITY portable power station with lithium-ion battery capacity of 1200Wh ,1000W pure sine wave output, PD Type-C Quick Charge (Max.45W), and Two ways of recharging (solar / AC). That makes it an ideal pick to neatly power up your emergency household and camping appliances. Besides the AC sockets, it integrates multiple DC and USB sockets. suitable for apartments.",
      //   // },

      //   // {
      //   //   name: "300VA Cloud Energy Sunbox",
      //   //   display_image:
      //   //     "http://res.cloudinary.com/imperiumng/image/upload/v1654093790/production/uwhuf35gfadkjf1n4i3o.jpg",
      //   //   price: 405000,
      //   //   url: "/product/300va-cloud-energy-sunbox",
      //   //   description:
      //   //     "300VA Cloud Energy Sunbox The following are present in the package: (1) 80 Watts Panel (2) 450Wh Lithium Ion Batteries (3) MPPT charge Controller (4) INVERTER 300Wa",
      //   // },

      //   // {
      //   //   name: "Tendon- 1KVA",
      //   //   display_image:
      //   //     "http://res.cloudinary.com/imperiumng/image/upload/v1644848749/production/drfc0b8ykbznspbzfvro.png",
      //   //   price: 461125,
      //   //   url: "/product/tendon-1kva-0001",
      //   //   description:
      //   //     "1KVA Inverter| 280W Panels (2 Units)| 12V 220Ah Tubular Battery| 1 Charge Controller",
      //   // },

      //   // {
      //   //   name: "Tendon- 1.5KVA",
      //   //   display_image:
      //   //     "http://res.cloudinary.com/imperiumng/image/upload/v1644852815/production/ixp1mvfcx7mmrwit3d3z.png",
      //   //   price: 784750,
      //   //   url: "/product/tendon-1-5kva",
      //   //   description:
      //   //     "1.5KVA Inverter| 280W Panels (4 Units) 12V 220Ah Tubular Battery (2 Units)| 1 Charge Controller",
      //   // },

      //   // {
      //   //   name: "MAXXI 1500WH SP",
      //   //   display_image:
      //   //     "http://res.cloudinary.com/imperiumng/image/upload/v1624013995/production/jrykbv0o1vkr2lrkvply.png",
      //   //   price: 590000,
      //   //   url: "/product/maxxi-1500wh-sp-0001",
      //   //   description:
      //   //     "MAXXI 1500Wh is a HUGE CAPACITY portable power station with lithium-ion battery capacity of 1500Wh ,1000W pure sine wave output, PD Type-C Quick Charge (Max.45W), and Two ways of recharging (solar / AC). That makes it an ideal pick to neatly power up your emergency household and camping appliances. Besides the AC sockets, it integrates DC and USB sockets. SOLAR PANELS. suitable for apartments.",
      //   // },

      //   // {
      //   //   name: "MAXXI 550WH SP",
      //   //   display_image:
      //   //     "http://res.cloudinary.com/imperiumng/image/upload/v1624010607/production/wzpe0os8qbjdpe1xm9u3.png",
      //   //   price: 230000,
      //   //   url: "/product/maxxi-550wh-sp-0001",
      //   //   description:
      //   //     "Our MAXXI 550Wh is a portable power station with lithium-ion battery capacity of 550Wh. It is accessible, safe, reliable and affordable. It lights up rooms, charges mobile phones and powers appliances like fans, TVs, Laptops, and Tablets – giving you a better living. A must-have for everyone! It is a gas-free compact portable multipurpose solar generator/battery backup with 150 WATTS SOLAR PANEL",
      //   // },

      //   // {
      //   //   name: "1kva Solar Essentials System",
      //   //   display_image:
      //   //     "http://res.cloudinary.com/imperiumng/image/upload/v1655377832/production/abiptfwamraeaudd4gfx.png",
      //   //   price: 538500,
      //   //   url: "/product/1kva-solar-essentials-system",
      //   //   description:
      //   //     "1 unit of battery(200AH) | 1 unit of Inverter(1kVA) | 4 pcs of Photovoltaic modules(200w) | 30Amps charge controller",
      //   // },

      //   // {
      //   //   name: "1.2kva Solar",
      //   //   display_image:
      //   //     "http://res.cloudinary.com/imperiumng/image/upload/v1655380086/production/san9rilehwuxcmjccdri.png",
      //   //   price: 560000,
      //   //   url: "/product/1-2kva-solar",
      //   //   description:
      //   //     "1 unit of battery(200AH) | 1 unit of hybrid Inverter with inbuilt 30Amps charge controller | 4 pcs of Photovoltaic modules(250w)",
      //   // },

      //   // {
      //   //   name: "SSW 1.5KVA/12V INVERTER (BATTERY & PANELS)",
      //   //   display_image:
      //   //     "http://res.cloudinary.com/imperiumng/image/upload/v1654239822/production/surhlddafinkv2gwzv7v.png",
      //   //   price: 640000,
      //   //   url: "/product/ssw-1-5kva-12v-inverter-battery-and-panels",
      //   //   description: "1.5KVA INVERTER 1 220AH BATTERY 2 250WATTS PANEL",
      //   // },

      //   // {
      //   //   name: "MAXXI 1200WH",
      //   //   display_image:
      //   //     "http://res.cloudinary.com/imperiumng/image/upload/v1624013576/production/nchh8kqfxhhem7woiqad.png",
      //   //   price: 430000,
      //   //   url: "/product/maxxi-1200wh-0001",
      //   //   description:
      //   //     "MAXXI 1200Wh is a HUGE CAPACITY portable power station with lithium-ion battery capacity of 1200Wh ,1000W pure sine wave output, PD Type-C Quick Charge (Max.45W), and Two ways of recharging (solar / AC). That makes it an ideal pick to neatly power up your emergency household and camping appliances. Besides the AC sockets, it integrates multiple DC and USB sockets. suitable for apartments.",
      //   // },
      // ],
    };
  },
  computed: {
    prodArr() {
      var arr = [];
      if (this.kva1) {
        arr.push("1KVA");
      }
      if (this.kva2) {
        arr.push("1.5KVA");
      }
      return arr;
    },
    valid() {
      var validRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      return (
        this.form.email.match(validRegex) &&
        this.form.name.length >= 3 &&
        this.form.phone_number.length &&
        this.form.branch.length &&
        this.form.region.length &&
        (this.kva1 || this.kva2)
      );
    },
  },
  methods: {
    Submit() {
      this.loading = true;
      this.form.product = this.prodArr;
      // var payload = this.form;
      let data = this.form;
      data = this.$helpers.encrypt(JSON.stringify(data));

      data = {
        data,
      };
      let payload = {
        data,
        path: "/sterling-human",
      };
      this.$store.dispatch("authRequest", payload).then((resp) => {
        resp.data = JSON.parse(this.$helpers.decrypt(resp.data.data));
        console.log(resp);
        if (resp.status === 200 && resp.statusText === "OK") {
          this.loading = false;
          this.email = "";

          this.$toast.success(
            "Sterling Human",
            "Upload Successful. You will be contacted by our representative",
            this.$toastPosition
          );
          this.form = {
            name: "",
            phone_number: "",
            email: "",
            branch: "",
            region: "",
          };
          this.kva1 = false;
          this.kva2 = false;
          this.loading = false;
          this.popup = false;
        }
      });
      // console.log(payload);
    },
  },
};
</script>

<style scoped lang="scss" >
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
p.red {
  font-size: 0.9rem;
  font-weight: bold;
  color: red;
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 110vh;
  background-color: #ffffff33;
  backdrop-filter: blur(3px);
  z-index: 999999999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inr {
  width: 340px;
  border-radius: 16px;
  padding: 16px;
  background-color: #fff;
  min-height: 100px;
  form {
    max-height: 65vh;
    overflow: hidden;
    overflow-y: auto;
    width: 100%;
    button.fbt {
      border: none;
      cursor: pointer;
      width: 100%;
      text-align: center;
      display: inline-block;
      background-color: #255e13;
      padding: 8px 0;
      border-radius: 15px;
      color: #fff;
      font-weight: 600;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      &:hover {
        background-color: #12111388;
      }
      &:disabled {
        background-color: #12111388;
        cursor: not-allowed;
      }
    }
  }
  h5 {
    text-align: center;
    font-weight: bold;
    color: #255e13;
  }
  .ch {
    display: flex;
    align-items: center;
    * {
      margin: 0;
      padding: 0;
    }
    input {
      margin-right: 5px;
    }
    label {
      cursor: pointer;
    }
  }
}
.prods {
  padding-top: 8vh;
}
.w-100 {
  width: 100%;
}
.center-text {
  text-align: center !important;
}
.testi {
  background-color: rgba(204, 222, 171, 0.1607843137);
  padding-top: 3rem !important;
  padding-bottom: 1.5rem !important;
}
.spesh {
  background: linear-gradient(
    180deg,
    #f4fcf2 3.01%,
    rgba(204, 222, 171, 0.1607843137)
  );
  background-size: cover;
  padding: 0;
}
.prods {
  /* background-color: rgba(204, 222, 171, 0.1607843137); */
  background: linear-gradient(
    180deg,
    rgba(204, 222, 171, 0.1607843137),
    rgba(241, 252, 237, 0)
  );
  padding-top: 3rem !important;
  padding-bottom: 1.5rem !important;
}
.cat-img {
  height: 240px;
}
.categoryHeadd {
  font-size: 47.6654px;
  line-height: 52px;
  color: #255e13;
  font-weight: 700;
}

.crd {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 9px;
  overflow: hidden;
  padding: 15px;
  color: #121113;
  margin-bottom: 23px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}
.prod .row {
  margin-bottom: 50px;
}
.imgg {
  width: 100%;
  margin: auto;
  /* margin-top: 5%; */
  border-radius: 9px 9px 0 0;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
}
.imgg img {
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}
.crd h2 {
  color: #255e13;
  font-weight: 700;
  font-size: 1.45rem;
  margin-top: 20px;
  margin-bottom: 30px;
}
.crd h4 {
  color: #255e13;
  font-weight: 600;
  font-size: 1.15rem;
}
span.fbt {
  width: 100%;
  text-align: center;
  display: inline-block;
  background-color: #255e13;
  padding: 8px 0;
  border-radius: 15px;
  color: #fff;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
span.fbt:hover {
  background-color: #12111388;
}
.comps span {
  font-weight: 600;
}
/* 
.load {
  min-height: 335px;
}
.comps {
  min-height: 150px;
}
.prcs {
  min-height: 154px;
} */
ul li {
  margin-bottom: 3px;
}
.testi a {
  color: #219653;
  font-weight: 500;
}
.para {
  width: 65%;
  margin: auto;
}
.prcs p {
  margin: 0;
}
.prcs p span {
  font-weight: 600;
}

@keyframes mymove {
  0% {
    opacity: 1;
    transform: translate(0px, 0px) scale(1);
  }
  100% {
    opacity: 0;
    transform: translate(50px, 0px) scale(2.5);
  }
}

span.move {
  display: inline-block;
  animation: mymove 1s infinite;
}
.tc p {
  font-weight: 500;
  font-size: 1.1rem;
}

@media (max-width: 768px) {
  .load {
    min-height: auto;
  }
  .comps {
    min-height: auto;
  }
  .prcs {
    min-height: auto;
  }
  .para {
    width: 100%;
    margin: auto;
  }
  .hh {
    display: none;
  }
}
</style>

